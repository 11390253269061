import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  public title: BehaviorSubject<string>;

  constructor(
    private configs: ConfigService,
    private titleService: Title
  ) {
    this.title = new BehaviorSubject<string>(`${this.configs.brandName} Online Casino`);
  }

  /**
   * The function `setTitle` sets the title of the page by combining the new title with the brand name from the
   * environment.
   * @param {string} newTitle - The `newTitle` parameter in the `setTitle` method is a string that represents the new title
   * that you want to set for the page.
   */
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle + ' | ' + this.configs.brandName);
    this.title.next(newTitle + ' | ' + this.configs.brandName);
  }

  resetTitle() {
    this.titleService.setTitle(`${this.configs.brandName} Online Casino`);
    this.title.next(`${this.configs.brandName} Online Casino`)
  }

}
