import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProviderModel} from "../../../models/provider.model";
import {Subscription} from "rxjs";
import {Game, Games} from "../../../models/game.class";
import {FormControl} from "@angular/forms";
import {GameService} from "../../../services/game.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TitleService} from "../../../services/title.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-selected-category',
  templateUrl: './selected-category.component.html',
  styleUrls: ['./selected-category.component.scss']
})
export class SelectedCategoryComponent implements OnInit, OnDestroy {

  readonly itemsPerPage: number = 24;

  chosenCategory?: string | null;
  gameSubs?: Subscription;
  games?: Games;
  allGames: Game[] = [];
  routeSubs?: Subscription;

  // TODO later: make subcategories for slots

  searchTerm = new FormControl('');
  searchSubs?: Subscription;

  titleTranslateSubs?: Subscription;

  showProviderSelector = false;
  providers: ProviderModel[] = [];
  numOfSelectedProviders: number = 0;
  providerFilter: { [key: string]: boolean } = {};
  validProviders: string[] = [];

  selectedSorting: string;
  originalUnsortedGames: Game[] = [];
  resetBySortOrFilterNeeded: boolean = false; //the change of value marks to itemsShowCase child component that initial value needed (init, filter or sort)

  filteredGames: Game[] = [];
  displayedGames: Game[]= [];
  isLoading = true;

  randomGamesForCarousel: Game[] = [];
  providerSubs: Subscription;

  constructor(
    private gameService: GameService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private translate: TranslateService
  ) {
    this.providerSubs = this.gameService.providers$.subscribe(p => {
      this.providers = p.filter(p => p.gameList && p.gameList.length)
      if (!this.providers.length){
        this.providers = this.gameService.providers;
      }
    })

    for (const provider of this.providers) {
      this.providerFilter[provider.routerLink] = false;
    }
    this.selectedSorting = 'POPULAR';
  }


  ngOnInit(): void {
      this.routeSubs = this.activatedRoute.params.subscribe(params => {
          this.chosenCategory = params['category'];

        // let categoryToNavigate = this.gameService.categoriesWithGames.find(cat => cat.toLowerCase().replace(' ', '') === this.chosenCategory)
        // if (!categoryToNavigate){
        //   // console.log(this.chosenCategory)
        //   this.router.navigate(['/providers', this.chosenCategory]);
        // }


         //setting page title
        if (this.chosenCategory){
          this.titleTranslateSubs?.unsubscribe();
          this.titleTranslateSubs = this.translate.stream([`CATEGORIES.${this.chosenCategory.toUpperCase()}`, 'CATEGORIES.PAGE_TITLE' ]).subscribe(data => {
            let translatedTitle = Object.values(data).join(" - ");
            this.titleService.setTitle(translatedTitle);
          })
        }

          // Resetting filters on route change
          this.searchTerm.setValue('');
          for (const provider of this.providers) {
            this.providerFilter[provider.routerLink] = false;
          }
          this.showProviderSelector = false;
          this.selectedSorting = 'POPULAR';
          this.isLoading = true;

          if (this.chosenCategory){
              this.gameSubs?.unsubscribe()
              this.gameSubs = this.gameService.getGames().subscribe({
                  next: games => {
                    if (games && games.length){
                      this.allGames = games.all;

                      //set carousels
                      this.randomGamesForCarousel = this.gameService.pickRandomGames(games.all, 16);

                      if (this.chosenCategory){
                        this.games = Games.fromGameArray(this.gameService.getGamesOfValidCategory(this.chosenCategory, games))

                        this.validProviders = [];
                        for (let game of this.games.all) {
                          let correctedProviderName = game.publisher.split('(')[0].trim();
                          if (!this.validProviders.includes(correctedProviderName)) {
                            this.validProviders.push(correctedProviderName);
                          }
                        }

                        this.loadItems(undefined);
                      }
                    } else if (games) { //no games available
                      this.isLoading = false;
                    }
                  }
              })

              this.searchSubs = this.searchTerm.valueChanges.subscribe(newValue => {
                this.loadItems(undefined)
              });
          }
      });

  }

  loadItems(itemsToShow: number | undefined) {
    //when itemsToShow is undefined, loadItems is called from parent, so initial itemsToShow value needed
    if (!itemsToShow){
      itemsToShow = this.itemsPerPage
    }
    if (itemsToShow === this.itemsPerPage){
      this.resetBySortOrFilterNeeded = !this.resetBySortOrFilterNeeded;
    }

    this.filterGames();
    // console.log(this.filteredGames)

    this.originalUnsortedGames = this.filteredGames.slice(0);
    let sortedGames = this.sortGames(this.selectedSorting);

    this.displayedGames = sortedGames.slice(0, itemsToShow);
    this.isLoading = false;

  }

  filterGames() {
    //filter by searchTerm, if any
    if (!this.searchTerm.value || this.searchTerm.value.trim() === '') {
      this.filteredGames = this.games?.all ?? [];
    }
    else {
      this.filteredGames = this.games?.all.filter(game => game.name.toLowerCase().includes((this.searchTerm.value as string).toLowerCase())) ?? []
    }

    //filter by selected providers, if any
    let selectedProviders = Object.keys(this.providerFilter).filter(provider => this.providerFilter[provider]);
    this.numOfSelectedProviders = selectedProviders.length;
    if (selectedProviders.length > 0) {
      this.filteredGames = this.filteredGames.filter(game =>
        selectedProviders.some(provider => game.publisher.split(" ").join('').toLowerCase().includes(provider))
      );
    }
  }

  sortOptionSelected(value: string){
    this.selectedSorting = value;
    this.loadItems(undefined)
  }

  sortGames(value: string){
    //todo delete this first version after TESTING
     // let sortedGames: Games = []
    // switch(value) {
    //   case 'AZ':
    //     sortedGames = this.filteredGames.slice(0).sort((a, b) => a.name.localeCompare(b.name));
    //     break;
    //   case 'ZA':
    //     sortedGames = this.filteredGames.slice(0).sort((a, b) => b.name.localeCompare(a.name));
    //     break;
    //   case 'POPULAR':
    //     sortedGames = this.originalUnsortedGames.slice(0);
    //     break;
    //   case 'FEATURED':
    //     sortedGames = this.filteredGames.slice(0).sort((a, b) => b.features.length - a.features.length);
    //     break;
    // }
    // return sortedGames;

    //sorting by enabled added for gamingpodium, and does not breaks rival
    // Step 1: sort by `enabled` status
    let gamesWithEnabledFirst = this.filteredGames.slice(0).sort((a, b) => (a.enabled === b.enabled) ? 0 : (a.enabled ? -1 : 1));

    // Step 2: apply additional sorting based on the selected option
    switch (value) {
      case 'AZ':
        return gamesWithEnabledFirst.sort((a, b) => {
          if (a.enabled === b.enabled) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        });
      case 'ZA':
        return gamesWithEnabledFirst.sort((a, b) => {
          if (a.enabled === b.enabled) {
            return b.name.localeCompare(a.name);
          }
          return 0;
        });
      case 'FEATURED':
        return gamesWithEnabledFirst.sort((a, b) => {
          if (a.enabled === b.enabled) {
            return b.features.length - a.features.length;
          }
          return 0;
        });
      case 'POPULAR':
        return gamesWithEnabledFirst;
      default:
        return gamesWithEnabledFirst;
    }
  }



  updateNotFoundImgUrl(event: Event) {
    (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=${(event.target as HTMLImageElement).alt}`;
  }

  launchGame(id: number): void {
    let chosenGame = this.games?.get(id);
    if (chosenGame && chosenGame.enabled !== false){
      if(chosenGame.launchUrl){
        this.router.navigate(['play', chosenGame.id])
      } else {
        this.router.navigate(['details', chosenGame.id])
      }
    }
  }

  toggleProviderSelector(): void {
    this.showProviderSelector = !this.showProviderSelector;
  }

  ngOnDestroy() {
    this.gameSubs?.unsubscribe();
    this.searchSubs?.unsubscribe();
    this.routeSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
  }


}
