import {Component, OnDestroy, OnInit } from '@angular/core';
import {Game, Games} from "../../models/game.class";
import {GameService} from "../../services/game.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {ProviderModel} from "../../models/provider.model";
import {TitleService} from "../../services/title.service";
import {UserModel} from "../../models/user.model";
import {UserService} from "../../services/user.service";
import {ConfigService} from "../../services/config.service";

declare var bootstrap: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  //todo: on gamingpodium - no games in displayed categories - dont show them OR show a no game message

  brandName: string;
  assetsPath: string;
  logoFileName: string;
  logoAltText: string;

  categories: string[];
  svgPaths: { [key: string]: string };

  games?: Games;
  gameSubscription?: Subscription;
  isLoading = true;

  readonly itemsPerPage = 12;
  itemsToShowInLobby?: number = undefined;
  gamesToDisplayByCategory: Game[][] = [];

  searchTerm = new FormControl('');
  searchSubscription?: Subscription;

  gamesByCategory: Game[][] = []
  filteredGamesByCategory: Game[][] = []

  randomGamesByCategoryForCarousels: Game[][] = [];

  displayedCategories: string[] = ['all', 'exclusive', 'new', 'top', 'live', 'featured', 'specialty']; //no slots, no table, no video poker on display
  gamesByDisplayedCategory: Game[][] = []

  providersArray!: ProviderModel[];
  providerSubs: Subscription;

  titleTranslateSubs?: Subscription;

  user: UserModel | null = null;
  userSubs?: Subscription;

  showNewBanner: boolean = false;





  constructor(
    private configs: ConfigService,
    private gameService: GameService,
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private titleService: TitleService,
    private userService: UserService
  ) {
    this.brandName = this.configs.brandName;
    this.assetsPath = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;

    this.categories = this.gameService.categories;
    this.svgPaths = this.gameService.svgPaths;

    if (this.brandName.toLowerCase().includes('candyland')){
      this.showNewBanner = true;
    }

    this.userSubs = this.userService.userObject.subscribe(user => {
      this.user = user
    })

    this.providerSubs = this.gameService.providers$.subscribe(p => {
        this.providersArray = p.filter(p => p.gameList && p.gameList.length)
      if (!this.providersArray.length){
        this.providersArray = this.gameService.providers;
      }
    })

    // this.titleService.resetTitle();
    this.titleTranslateSubs?.unsubscribe();
    this.titleTranslateSubs = this.translate.stream("HOME.PAGE_TITLE").subscribe(data => {
      this.titleService.setTitle(data);
    })

  }

  ngOnInit() {
    this.gameSubscription?.unsubscribe();
    this.gameSubscription = this.gameService.getGames().subscribe({
      next: games => {
        if (games && games.length) {
          this.games = games;
          // console.log(this.games)

          //grouping games into categories
          this.gamesByCategory = this.gameService.categorizeGames(this.games);

          //set games for carousels
          this.randomGamesByCategoryForCarousels = this.gamesByCategory.map(category => this.gameService.pickRandomGames(category, 16));

          //show only displayed-category games
          this.gamesByDisplayedCategory = [];
          for (let i = 0; i < this.categories.length; i++) {
            if ( this.displayedCategories.includes(this.categories[i])){
              this.gamesByDisplayedCategory.push(this.gamesByCategory[i])
            }
          }


          //to show games (without filtering)
          this.loadItems();
          this.isLoading = false;
        } else if (games) {
          //we could not fetch games in service
          this.isLoading = false;
        }
      },
      error: (err) => {
        // console.log(err);
        this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1'), {toastClass: 'ngx-toastr yourclass'})

      }
    });

    this.searchSubscription = this.searchTerm.valueChanges.subscribe(newValue => {
      this.loadItems();
    });
  }



  filterGames() {
    //filter by searchTerm, if any
    if (!this.searchTerm.value || this.searchTerm.value.trim() === '') {
      // this.filteredGamesByCategory = this.gamesByCategory;
      this.filteredGamesByCategory = this.gamesByDisplayedCategory; //to show only displayed category games
    }
    else {
      this.filteredGamesByCategory = this.gamesByDisplayedCategory.map(categoryGames => {
        return categoryGames.filter(game => game.name.toLowerCase().includes((this.searchTerm.value as string).toLowerCase()));
      });
    }

    // Sort games by `enabled` property - games without enabled property will be treated as false in the sort
    this.filteredGamesByCategory = this.filteredGamesByCategory.map(categoryGames =>
      categoryGames.sort((a, b) => (a.enabled === b.enabled) ? 0 : a.enabled ? -1 : 1)
    );
  }

  loadItems() {
    this.filterGames();
    // console.log(this.filteredGamesByCategory)

    this.gamesToDisplayByCategory = this.filteredGamesByCategory.map((categoryGames,index) => {
      if (index === 0 && this.itemsToShowInLobby) {
        return categoryGames.slice(0, this.itemsToShowInLobby);
      }
      return categoryGames.slice(0, this.itemsPerPage);
    })
  }

  loadMore(itemsToShow: number) {
    this.itemsToShowInLobby = itemsToShow;
    this.loadItems()
  }




  launchGame(id:number): void {
      let chosenGame = this.games?.get(id);
      if (chosenGame && chosenGame.enabled !== false){
        // chosenGame.enabled is "undefined" for Rival brands and "true" for enabled games in gamingpodium brands
        if(chosenGame.launchUrl){
          this.router.navigate(['play', chosenGame.id])
        } else {
        this.router.navigate(['details', chosenGame.id])
        }
      }
  }

  openLogin(){
    this.router.navigate(['play', 666])

  }

  //use placeholder when the image is not found
  updateNotFoundImgUrl(event: Event) {
    (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=${(event.target as HTMLImageElement).alt}`;
  }

  getSanitizedSvgPath(category: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.svgPaths[category.toLowerCase()]);
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe();
    this.gameSubscription?.unsubscribe();
    this.providerSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
  }
}
