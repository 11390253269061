import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-no-money-modal',
  templateUrl: './no-money-modal.component.html',
  styleUrls: ['./no-money-modal.component.scss']
})
export class NoMoneyModalComponent {

  @ViewChild('closeNoMoneyButton') closeNoMoneyButton!: ElementRef;


  constructor(
    private router: Router
  ) {  }

  goToCashier(): void {
    this.closeNoMoneyButton.nativeElement.click();
    this.router.navigate(['account', 'cashier'])
  }

}
