export const games = [
  {
    "id": 3008,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "4-rows"
    ],
    "name": "Realm of Gaia",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/realm_of_gaia.png",
    "cdn": {},
    "category": "Video Slot, 5 reels, 4 rows",
    "features": [],
    "publisher": "TGS",
    "slug": "realm-of-gaia",
    "hash": "a071c57e0f726907a74f8d0d3b5a581a5b7fcc04",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 5001,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Plinko",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/69b8c2070863343c2c92d63821d53c9e60403762/plinko.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/69b8c2070863343c2c92d63821d53c9e60403762/plinko.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "HacksawGaming",
    "slug": "plinko",
    "hash": "69b8c2070863343c2c92d63821d53c9e60403762",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 114,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Deep Sea Dazzle",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/deep_sea_dazzle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/04f5e1ae8c076d0a474cd391239db394ebf99450/deep-sea-dazzle.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/04f5e1ae8c076d0a474cd391239db394ebf99450/deep-sea-dazzle.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "deep-sea-dazzle",
    "hash": "04f5e1ae8c076d0a474cd391239db394ebf99450",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 108,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Lucky Dama Muerta",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_dama_muerta.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d50fa84da1f0d4f9e4e26419c2c84920a61f73b8/lucky-dama-muerta.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d50fa84da1f0d4f9e4e26419c2c84920a61f73b8/lucky-dama-muerta.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "lucky-dama-muerta",
    "hash": "d50fa84da1f0d4f9e4e26419c2c84920a61f73b8",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 121,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Chipy",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/chipy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3f6624e3b71b006069bc8fa3c75fef782562767d/chipy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3f6624e3b71b006069bc8fa3c75fef782562767d/chipy.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Affiliate",
    "slug": "chipy",
    "hash": "3f6624e3b71b006069bc8fa3c75fef782562767d",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3001,
    "tag": "",
    "tags": [
      "3-roll-spin"
    ],
    "name": "Joker 50",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/other/50jokers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ab87419332eee73d6be4175f278f904f0bf26cd6/joker-50.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ab87419332eee73d6be4175f278f904f0bf26cd6/joker-50.512x512.webp"
    },
    "category": "3 roll spin",
    "features": [],
    "publisher": "Yggdrasil",
    "slug": "joker-50",
    "hash": "ab87419332eee73d6be4175f278f904f0bf26cd6",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 102,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Wild Chicago",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_chicago.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1f94d691ff789d0c8b871d915bd183deec582612/wild-chicago.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1f94d691ff789d0c8b871d915bd183deec582612/wild-chicago.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "wild-chicago",
    "hash": "1f94d691ff789d0c8b871d915bd183deec582612",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 130,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "LuckOfTheIrish",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/luck_of_the_irish.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ae69c7578130794ac434288693a5b6f1d973e361/luckoftheirish.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ae69c7578130794ac434288693a5b6f1d973e361/luckoftheirish.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "luckoftheirish",
    "hash": "ae69c7578130794ac434288693a5b6f1d973e361",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 119,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "God of Luxor",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/god_of_luxor.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/115e600a6822ea1d000722a6559c1b43b8c7a971/god-of-luxor.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/115e600a6822ea1d000722a6559c1b43b8c7a971/god-of-luxor.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "god-of-luxor",
    "hash": "115e600a6822ea1d000722a6559c1b43b8c7a971",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 110,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Wild Tiger",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_tiger.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5283cfb34c64395b235eecf24c47849393840fcb/wild-tiger.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5283cfb34c64395b235eecf24c47849393840fcb/wild-tiger.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "wild-tiger",
    "hash": "5283cfb34c64395b235eecf24c47849393840fcb",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 115,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Lucky Cat",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_cat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/63c7f81c3e0d5fb42f94d98bf0c721b85ae79e73/lucky-cat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/63c7f81c3e0d5fb42f94d98bf0c721b85ae79e73/lucky-cat.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "lucky-cat",
    "hash": "63c7f81c3e0d5fb42f94d98bf0c721b85ae79e73",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 123,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "LotterySlot",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lotteryslot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/282884307ae2a7558e896f6d6b365bfe06f900d2/lotteryslot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/282884307ae2a7558e896f6d6b365bfe06f900d2/lotteryslot.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "lotteryslot",
    "hash": "282884307ae2a7558e896f6d6b365bfe06f900d2",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3010,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "4-rows"
    ],
    "name": "Rift Raiders",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/rift_raiders.png",
    "cdn": {},
    "category": "Video Slot, 5 reels, 4 rows",
    "features": [],
    "publisher": "TGS",
    "slug": "rift-raiders",
    "hash": "49e9f1549af86f977946b4264887fe17c937f623",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 116,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Nodeposit Bonus",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/nodeposit_bonus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/62188f4f302164015c521ad0fc8ae2249ca116e5/nodeposit-bonus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/62188f4f302164015c521ad0fc8ae2249ca116e5/nodeposit-bonus.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Affiliate",
    "slug": "nodeposit-bonus",
    "hash": "62188f4f302164015c521ad0fc8ae2249ca116e5",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 106,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Wild Cash x9990",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash_x9990.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3440f99365b6a1dd8a74bd996a6434460f9f3d01/wild-cash-x9990.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3440f99365b6a1dd8a74bd996a6434460f9f3d01/wild-cash-x9990.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "wild-cash-x9990",
    "hash": "3440f99365b6a1dd8a74bd996a6434460f9f3d01",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 5003,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Plinko3",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko3.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9ce9255b37ec33ff1416d26e5bced5b17ef93dc8/plinko3.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9ce9255b37ec33ff1416d26e5bced5b17ef93dc8/plinko3.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "plinko3",
    "hash": "9ce9255b37ec33ff1416d26e5bced5b17ef93dc8",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 111,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Burning Chilli X",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/burning_chilli_x.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f1f0774b55d44645cfd56b999348f2f00233783a/burning-chilli-x.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f1f0774b55d44645cfd56b999348f2f00233783a/burning-chilli-x.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "burning-chilli-x",
    "hash": "f1f0774b55d44645cfd56b999348f2f00233783a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 122,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "DragonEgg",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/dragonegg.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/75626a62c6e003fed73f9aebf5046219b24d3610/dragonegg.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/75626a62c6e003fed73f9aebf5046219b24d3610/dragonegg.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "dragonegg",
    "hash": "75626a62c6e003fed73f9aebf5046219b24d3610",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 5002,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "PlinkoChristmas",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/plinko2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/33e34e5184bb48c8b0d550d6638dc542be78a7ef/plinkochristmas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/33e34e5184bb48c8b0d550d6638dc542be78a7ef/plinkochristmas.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "plinkochristmas",
    "hash": "33e34e5184bb48c8b0d550d6638dc542be78a7ef",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 125,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Jackowins",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/jackowins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3765a8cb8e1268c85112c4f021247f62a9d6122e/jackowins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3765a8cb8e1268c85112c4f021247f62a9d6122e/jackowins.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "jackowins",
    "hash": "3765a8cb8e1268c85112c4f021247f62a9d6122e",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 5004,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Donut Division",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/1608.jpg",
    "cdn": {},
    "category": "Slots",
    "features": [],
    "publisher": "HacksawGaming",
    "slug": "donut-division",
    "hash": "9cf16a54caa8777a7550fe5a20eb91a450a17146",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 101,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Hottest 666",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/hottest666.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/615e6653f08cb0a4765e7a1d3f2cdd853408c2f3/hottest-666.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/615e6653f08cb0a4765e7a1d3f2cdd853408c2f3/hottest-666.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "hottest-666",
    "hash": "615e6653f08cb0a4765e7a1d3f2cdd853408c2f3",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 113,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Funky Frogs",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/funky_frogs.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/360a4612daefe8ef06f3733c210b3f7432c45409/funky-frogs.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/360a4612daefe8ef06f3733c210b3f7432c45409/funky-frogs.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "funky-frogs",
    "hash": "360a4612daefe8ef06f3733c210b3f7432c45409",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 100,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Lady Wolf Moon",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lady_wolf_moon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8484634de95dc2fd4e9a35c4a0feb5500854df6a/lady-wolf-moon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8484634de95dc2fd4e9a35c4a0feb5500854df6a/lady-wolf-moon.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "lady-wolf-moon",
    "hash": "8484634de95dc2fd4e9a35c4a0feb5500854df6a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2003,
    "tag": "",
    "tags": [
      "crash",
      "exclusive"
    ],
    "name": "Skull or Cash",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/skull-or-cash/skull-or-cash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5079c8aaa968b652df3a98fbe0715f7a892bc3bf/skull-or-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5079c8aaa968b652df3a98fbe0715f7a892bc3bf/skull-or-cash.512x512.webp"
    },
    "category": "crash",
    "features": [],
    "publisher": "Instanet",
    "slug": "skull-or-cash",
    "hash": "5079c8aaa968b652df3a98fbe0715f7a892bc3bf",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3002,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "3-rows"
    ],
    "name": "Big Top Terror",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/other/bigtopterror.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7504fdb88aa39298ae05da040e4f1082a61d125d/big-top-terror.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7504fdb88aa39298ae05da040e4f1082a61d125d/big-top-terror.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 3 rows",
    "features": [],
    "publisher": "Yggdrasil",
    "slug": "big-top-terror",
    "hash": "7504fdb88aa39298ae05da040e4f1082a61d125d",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3003,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "4-rows"
    ],
    "name": "Wild Storm Legionnaire",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/other/wild_storm_legionnaire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c6ed1e5c37df8ac3a7fe2a4bbf61e98031c4a482/wild-storm-legionnaire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c6ed1e5c37df8ac3a7fe2a4bbf61e98031c4a482/wild-storm-legionnaire.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 4 rows",
    "features": [],
    "publisher": "Yggdrasil",
    "slug": "wild-storm-legionnaire",
    "hash": "c6ed1e5c37df8ac3a7fe2a4bbf61e98031c4a482",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 4000,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "3-rows"
    ],
    "name": "Badge Blitz",
    "image": "https://www.bestcasinohq.com/wp-content/uploads/2024/10/15265_66f74bf446efa.jpg",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/27270d6c869b7971054186c78262561e2f50b6ee/badge-blitz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/27270d6c869b7971054186c78262561e2f50b6ee/badge-blitz.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 3 rows",
    "features": [],
    "publisher": "Pragmatic",
    "slug": "badge-blitz",
    "hash": "27270d6c869b7971054186c78262561e2f50b6ee",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3005,
    "tag": "",
    "tags": [
      "5-roll-spin",
      "avalanche"
    ],
    "name": "Riptide Treasures",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/riptidetreasures2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4a570cc764bf08d69310c5a444bc07808e592568/riptide-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4a570cc764bf08d69310c5a444bc07808e592568/riptide-treasures.512x512.webp"
    },
    "category": "5 roll spin, avalanche",
    "features": [],
    "publisher": "TGS",
    "slug": "riptide-treasures",
    "hash": "4a570cc764bf08d69310c5a444bc07808e592568",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3006,
    "tag": "beta",
    "tags": [
      "beta",
      "5-roll-spin",
      "avalanche"
    ],
    "name": "Ion",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/ion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/74ac66527df46aa8ea4f0bbff329e8fda23d0d5a/ion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/74ac66527df46aa8ea4f0bbff329e8fda23d0d5a/ion.512x512.webp"
    },
    "category": "5 roll spin, avalanche",
    "features": [],
    "publisher": "TGS",
    "slug": "ion",
    "hash": "74ac66527df46aa8ea4f0bbff329e8fda23d0d5a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3007,
    "tag": "",
    "tags": [
      "5-roll-spin",
      "avalanche"
    ],
    "name": "Ember",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/ember.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/af5aa298330d15cef40908e44774c18c697106a1/ember.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/af5aa298330d15cef40908e44774c18c697106a1/ember.512x512.webp"
    },
    "category": "5 roll spin, avalanche",
    "features": [],
    "publisher": "TGS",
    "slug": "ember",
    "hash": "af5aa298330d15cef40908e44774c18c697106a1",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 128,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "CasinosAnalyzer",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/casinos_analyzer.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/91204495c918075feabda225f2f59f5fd4d87e1a/casinosanalyzer.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/91204495c918075feabda225f2f59f5fd4d87e1a/casinosanalyzer.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Affiliate",
    "slug": "casinosanalyzer",
    "hash": "91204495c918075feabda225f2f59f5fd4d87e1a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 129,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "TitansWrath",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/titans_wrath.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/90d9e3ddacf4598fdc093a60529bcb7c2913889a/titanswrath.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/90d9e3ddacf4598fdc093a60529bcb7c2913889a/titanswrath.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "titanswrath",
    "hash": "90d9e3ddacf4598fdc093a60529bcb7c2913889a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 4001,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "4-rows"
    ],
    "name": "Jackpot Hunter",
    "image": "https://mediumrare.imgix.net/28758241dc37505c094f446af08c7985c907eedbfb6ad466607dfc03e3223981?q=85",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec59ab0d1a15c1736cfd427839fbaef8405d4f1f/jackpot-hunter.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec59ab0d1a15c1736cfd427839fbaef8405d4f1f/jackpot-hunter.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 4 rows",
    "features": [],
    "publisher": "Pragmatic",
    "slug": "jackpot-hunter",
    "hash": "ec59ab0d1a15c1736cfd427839fbaef8405d4f1f",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 104,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Lucky Oak",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/lucky_oak.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3d13fa8a6a6af844281dd3908e5043d583c5de5a/lucky-oak.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3d13fa8a6a6af844281dd3908e5043d583c5de5a/lucky-oak.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "lucky-oak",
    "hash": "3d13fa8a6a6af844281dd3908e5043d583c5de5a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 120,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Bingo Slot",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/og_bingo_slot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f6bd4e4fe2f4e16a00178bb52bc65dac92c87aa3/bingo-slot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f6bd4e4fe2f4e16a00178bb52bc65dac92c87aa3/bingo-slot.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "bingo-slot",
    "hash": "f6bd4e4fe2f4e16a00178bb52bc65dac92c87aa3",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 127,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "PirateBonus",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/piratebonus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/31b325813b7a56461e93bc64e4b22272bd30e01a/piratebonus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/31b325813b7a56461e93bc64e4b22272bd30e01a/piratebonus.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "piratebonus",
    "hash": "31b325813b7a56461e93bc64e4b22272bd30e01a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 109,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Gift Rush",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/gift_rush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9f70773df3dc8bcdcc5b4ede1ce043e76b1ee1f7/gift-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9f70773df3dc8bcdcc5b4ede1ce043e76b1ee1f7/gift-rush.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "gift-rush",
    "hash": "9f70773df3dc8bcdcc5b4ede1ce043e76b1ee1f7",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 5000,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Zeus",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/zeus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dfbf4bd46f541a10a17b6cbc56f0492f7bce59d3/zeus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dfbf4bd46f541a10a17b6cbc56f0492f7bce59d3/zeus.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "HacksawGaming",
    "slug": "zeus",
    "hash": "dfbf4bd46f541a10a17b6cbc56f0492f7bce59d3",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 107,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Luck & Magic x999",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/luck_and_magic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8a21a29f334c2028acd962eb18754a802ca021ba/luck---magic-x999.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8a21a29f334c2028acd962eb18754a802ca021ba/luck---magic-x999.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "luck---magic-x999",
    "hash": "8a21a29f334c2028acd962eb18754a802ca021ba",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 124,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "NodepositKings",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/nodeposit_kings.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e36f1c102a26fb2ac899c1d19651f0172f10f16b/nodepositkings.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e36f1c102a26fb2ac899c1d19651f0172f10f16b/nodepositkings.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Affiliate",
    "slug": "nodepositkings",
    "hash": "e36f1c102a26fb2ac899c1d19651f0172f10f16b",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3000,
    "tag": "",
    "tags": [
      "5-roll-spin",
      "avalanche"
    ],
    "name": "Monkeys Go Bananas",
    "image": "https://cdn.35c1a7aaa52010e257ead133ed258461.0x6e.com/game_icons/other/monkeysgobananas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/572a96eb65e54472834447480fdea4cf6e7d5ba8/monkeys-go-bananas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/572a96eb65e54472834447480fdea4cf6e7d5ba8/monkeys-go-bananas.512x512.webp"
    },
    "category": "5 roll spin, avalanche",
    "features": [],
    "publisher": "Yggdrasil",
    "slug": "monkeys-go-bananas",
    "hash": "572a96eb65e54472834447480fdea4cf6e7d5ba8",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 103,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Wild Cash",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4cb591bf8631e0ebd890a49e8429e2c005ad88a1/wild-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4cb591bf8631e0ebd890a49e8429e2c005ad88a1/wild-cash.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "wild-cash",
    "hash": "4cb591bf8631e0ebd890a49e8429e2c005ad88a1",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 126,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "SpiritOfTheSpins",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/spirit_of_the_spins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6d9fedf568434ebb16a5e2c56580444ae8c223d6/spiritofthespins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6d9fedf568434ebb16a5e2c56580444ae8c223d6/spiritofthespins.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "spiritofthespins",
    "hash": "6d9fedf568434ebb16a5e2c56580444ae8c223d6",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 105,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Wild Cash Dice",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/wild_cash_dice.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/11762f202b8b83c0b194095cd0e6e9989dfe8e71/wild-cash-dice.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/11762f202b8b83c0b194095cd0e6e9989dfe8e71/wild-cash-dice.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "Bgaming",
    "slug": "wild-cash-dice",
    "hash": "11762f202b8b83c0b194095cd0e6e9989dfe8e71",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3009,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "4-rows"
    ],
    "name": "Realm of Whimsicality",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/realm_of_whimsicality.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6e09e9d915b06d76e64af94ef86ccbcb857a36b1/realm-of-whimsicality.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6e09e9d915b06d76e64af94ef86ccbcb857a36b1/realm-of-whimsicality.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 4 rows",
    "features": [],
    "publisher": "TGS",
    "slug": "realm-of-whimsicality",
    "hash": "6e09e9d915b06d76e64af94ef86ccbcb857a36b1",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2001,
    "tag": "",
    "tags": [
      "crash",
      "exclusive"
    ],
    "name": "Get Up!",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/get-up/get-up.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/723772df758caff0efbbd9505137a0316149426a/get-up-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/723772df758caff0efbbd9505137a0316149426a/get-up-.512x512.webp"
    },
    "category": "crash",
    "features": [],
    "publisher": "Instanet",
    "slug": "get-up-",
    "hash": "723772df758caff0efbbd9505137a0316149426a",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 117,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Cosmic Reels",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/cosmic_reels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6fa127f2d001a139c00d5f205fa295883445c4e7/cosmic-reels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6fa127f2d001a139c00d5f205fa295883445c4e7/cosmic-reels.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "cosmic-reels",
    "hash": "6fa127f2d001a139c00d5f205fa295883445c4e7",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2000,
    "tag": "",
    "tags": [
      "crash",
      "exclusive"
    ],
    "name": "Delivery",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/delivery/delivery.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8ed6a3bc66b96744d72ce47a59ca24d517bd7686/delivery.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8ed6a3bc66b96744d72ce47a59ca24d517bd7686/delivery.512x512.webp"
    },
    "category": "crash",
    "features": [],
    "publisher": "Instanet",
    "slug": "delivery",
    "hash": "8ed6a3bc66b96744d72ce47a59ca24d517bd7686",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2002,
    "tag": "",
    "tags": [
      "crash",
      "exclusive"
    ],
    "name": "Plane",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/plane/plane.png\n",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f4db7dbe0a0ca5646af4cca3f75a8ab60d3e3132/plane.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f4db7dbe0a0ca5646af4cca3f75a8ab60d3e3132/plane.512x512.webp"
    },
    "category": "crash",
    "features": [],
    "publisher": "Instanet",
    "slug": "plane",
    "hash": "f4db7dbe0a0ca5646af4cca3f75a8ab60d3e3132",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 112,
    "tag": "",
    "tags": [
      "slots"
    ],
    "name": "Foxy Lady Forest",
    "image": "https://gscdn.0x6e.com/gm.crazywinners.dev/user/static/games_icons_350x350/foxy_lady_forest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1e946bc3f972faa566cb87b8194cef96c118c925/foxy-lady-forest.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1e946bc3f972faa566cb87b8194cef96c118c925/foxy-lady-forest.512x512.webp"
    },
    "category": "Slots",
    "features": [],
    "publisher": "TGS",
    "slug": "foxy-lady-forest",
    "hash": "1e946bc3f972faa566cb87b8194cef96c118c925",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 3004,
    "tag": "",
    "tags": [
      "video-slot",
      "5-reels",
      "3-rows"
    ],
    "name": "The Cursed Sea",
    "image": "https://gscdn-uat.0x6e.com/user/static/icons/games_icons_350x350/thecursedsea.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/62a14147d87591b00114be21c4ac65453b8aa837/the-cursed-sea.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/62a14147d87591b00114be21c4ac65453b8aa837/the-cursed-sea.512x512.webp"
    },
    "category": "Video Slot, 5 reels, 3 rows",
    "features": [],
    "publisher": "TGS",
    "slug": "the-cursed-sea",
    "hash": "62a14147d87591b00114be21c4ac65453b8aa837",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2004,
    "tag": "",
    "tags": [
      "crash",
      "exclusive"
    ],
    "name": "Help",
    "image": "https://cdn.a2f0fe27efef7fbc527d89623667004d.0x6e.com/games/help/help.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/75d336826d1b23ee98ac1a0af2c7fefeee21e645/help.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/75d336826d1b23ee98ac1a0af2c7fefeee21e645/help.512x512.webp"
    },
    "category": "crash",
    "features": [],
    "publisher": "Instanet",
    "slug": "help",
    "hash": "75d336826d1b23ee98ac1a0af2c7fefeee21e645",
    "related": [],
    "sort": 1000,
    "added": "2024-01-24T07:42:33.019Z"
  }
]