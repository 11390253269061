import { Pipe, PipeTransform } from '@angular/core';
import {GameModel} from "../models/game.model";

@Pipe({
  name: 'filterByTerm'
})
export class FilterByTermPipe implements PipeTransform {
  transform(games: GameModel[], searchText: string): any[] {
    if (!games) return [];
    if (!searchText || searchText === "") return games;

    searchText = searchText.toLowerCase();


    let filteredGames: GameModel[] = games.filter(g => g.name.toLowerCase().includes(searchText));

    let result: GameModel[] = [];
    if (filteredGames.length) {
      result = filteredGames
    }

    return result;
  }
}
