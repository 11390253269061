import {GameModel} from "./game.model";
import {ProviderModel} from "./provider.model";

export class Game implements GameModel {
  constructor(public raw: GameModel) {

  }

  get id(): number {
    return this.raw.id
  }

  get name(): string {
    return this.raw.name
  }

  get image(): string {
    if (this.name.toLowerCase().includes('freshdeck')) {
      return `assets/games/icons/rival/${this.id}.png`
    }
    return this.raw.image
  }

  get category(): string {
    return this.raw.category
  }

  get publisher(): string {
    //never use (Sandstrom) in Instanet games publisher name even if Rival is unable to remove it
    if (this.raw.publisher.toLowerCase().includes('sandstorm'))
      return this.raw.publisher.replace('(Sandstorm)', '').trim();

    return this.raw.publisher
  }

  get launchUrl(): string | null {
    return this.raw.launchUrl
  }

  get tag(): null | string {
    return this.raw.tag
  }

  get features(): any[] {
    return this.raw.features
  }

  get enabled(): boolean | undefined {
    return this.raw.enabled
  }

}

export class Games {

  #games: Game[] = []

  constructor(public games: GameModel[]) {
      this.#games = games.map(game => new Game(game))

  }

  static fromGameArray(games: Game[]){
    return new Games(games.map(game => {
      // game.raw.image = game.image
      // return game.raw
      const rawCopy = {...game.raw}
      rawCopy.image = game.image
      return rawCopy
    }))
  }

  get all(){
    return this.#games
  }



  get length(){
    return this.#games.length
  }

  get (id: number){
    return this.#games.find(game=> game.id == id)
  }

  byProviders(providers: ProviderModel[]) {
    //get providers with games dynamically
    const uniquePublishers = Array.from(new Set(this.#games.map(game => game.publisher)));
    const providersWithGames = uniquePublishers.map(publisher => {
      const provider = providers.find(p => publisher.toLowerCase().includes(p.name.toLowerCase()));
      const gameList = this.#games.filter(game => game.publisher.toLowerCase() === publisher.toLowerCase());
      return {
        routerLink: provider ? provider.routerLink : publisher.toLowerCase().split(" ")[0],
        name: publisher,
        imagePath: provider ? provider.imagePath : this.generateProviderSvgPlaceholderUrl(publisher),
        gameList: gameList
      } as ProviderModel;
    });

    // Order providers by the length of the gameList in descending order
    providersWithGames.sort((a, b) => (b.gameList?.length || 0) - (a.gameList?.length || 0));

    return providersWithGames;
  }

  generateProviderSvgPlaceholderUrl(text: string): string {
    const svg = `
    <svg width="300" height="150" xmlns="http://www.w3.org/2000/svg">
      <foreignObject width="100%" height="100%">
        <div xmlns="http://www.w3.org/1999/xhtml" style="width:100%;height:100%;display:flex;justify-content:center;align-items:center;text-align:center;color:#fff;font-size:50px;font-weight:bold;line-height:1.2;font-family:sans-serif;">${text}</div>
      </foreignObject>
    </svg>
  `;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  }




}
