<div class="modal fade" id="noMoneyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="noMoneyModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeNoMoneyButton></button>
      </div>
      <div class="modal-body">
        <p>{{ 'NO_MONEY.P1' | translate }}</p>
        <p class="mb-0">{{ 'NO_MONEY.P2' | translate }}</p>
      </div>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-outline-primary" (click)="goToCashier()"> {{ 'NO_MONEY.OK_BTN' | translate }}</button>
<!--        <button type="button" class="btn btn-outline-danger" (click)="logout()"> some button to log out {{ 'LOGOUT.TITLE' | translate }}</button>-->
      </div>
    </div>
  </div>
</div>
