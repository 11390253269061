import {Component} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ProviderModel} from "../../../models/provider.model";
import {GameService} from "../../../services/game.service";
import {TranslateService} from "@ngx-translate/core";
import {TitleService} from "../../../services/title.service";

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent {
  providers$? : Observable<ProviderModel[]>;

  titleTranslateSubs?: Subscription;

  constructor(
    private gameService: GameService,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    this.providers$ = this.gameService.providers$;
  }

  ngOnInit(){
      this.titleTranslateSubs?.unsubscribe();
      this.titleTranslateSubs = this.translate.stream("PROVIDERS.PAGE_TITLE").subscribe(data => {
        this.titleService.setTitle(data);
      })
  }

  ngOnDestroy(){
    this.titleTranslateSubs?.unsubscribe();
  }


}
