import { environment as gamingPodiumEnvironment } from './environment.gamingpodium';


export const environment = {
  ...gamingPodiumEnvironment,
  // production: true, //if we want to see it online + angular.json/build optimization to true

  rivalName: "jackpotnexus",
  casinoId: 2,
  GA_TRACKING: "", //todo get a tracker

  rivalChatGroupName: "Jackpot Nexus", //not needed as it is not rival chat
  prettyName: "Jackpot Nexus",
  contactEmail: "support@jackpotnexus.com", //todo
  docsEmail: "support@jackpotnexus.com", //todo

  chatSystem: "tawk",
  tawk_propertyId: "6696c9e7becc2fed69266443",
  tawk_widgetId: "1i2ug9cme",
  tawk_apiKey: "287cbc1cab1927caaa8c6af25ee78f9a6eae27a3",


  assetsPath: undefined, // or assetsPath: "assets/brands/jackpotnexus"


  //SEO - from default
  metaDescription: "Play Online Casino games at Jackpot Nexus Live Casino! Premium high volatility slot games, table games, and live dealer gambling with enticing bonuses and a VIp rewarding loyalty program. Secure and fast payment even in bitcoin and 24/7 trustworthy customer support by Jackpot Nexus Online Casino.",
  // logoName: "logo.png",
  // logoAltText: ""


};


