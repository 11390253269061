<main class="container-fluid px-0 pb-3 pb-lg-5">

  <div class="px-3 py-3 bg-darkest">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="h3 fw-bold mb-0">{{provider ? provider.name : chosenProvider}} {{ chosenProvider === 'tomhorn' ? '' : 'CATEGORIES.TITLE' | translate }}</h1>
        <div class="provider-icon-overlay">
          <img *ngIf="provider" [src]="provider.imagePath" alt="provider background image" width="100%">
        </div>
      </div>
    </div>
  </div>


  <div class="container-lg" >
    <div class="px-0" *ngIf="provider && provider.gameList && provider.gameList.length; else noMatchBlock">
      <!-- Searchbar -->
      <div class="my-2 my-md-4">
        <form class="input-group searchbar bg-darker">
        <span class="input-group-text" id="search-input">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
          <input type="text" name="searchInput" class="form-control" [placeholder]=" 'HOME.SEARCHBAR_PLACEHOLDER' | translate" aria-label="searchbar" aria-describedby="search-input" [formControl]="searchTerm">
        </form>
      </div>

      <section id="category-nav" class="my-4">

        <div class="overflow-auto" id="pills-tab">
          <ul class="nav nav-pills bg-darkest rounded-pill flex-nowrap" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let category of activeCategories; let i = index">
              <button #tab class="nav-link rounded-pill d-flex flex-row text-nowrap py-2 ms-1" [class.active]="i == 0"
                      [id]="'pills-'+category.toLowerCase().split(' ').join('-')+'-tab'" data-bs-toggle="pill"
                      [attr.data-bs-target]="'#pills-'+category.toLowerCase().split(' ').join('-')" type="button"
                      role="tab" aria-controls="pills-home" aria-selected="false" (click)="loadItems(undefined)" >
                <svg class="casino-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
                  <g [innerHTML]="getSanitizedSvgPath(category.toLowerCase().replace(' ', ''))"></g>
                </svg>
                <span>{{"CATEGORIES." + (category.split(' ').join('') | uppercase) | translate}}</span>
              </button>
            </li>
          </ul>
        </div>


        <!-- sort by section-->
        <div class="d-flex justify-content-between my-4">

          <div class="">
            <a class="btn btn-outline-secondary rounded-pill d-flex align-items-center" routerLink="/providers">{{'PROVIDERS.PROV_LINK_LABEL' | translate}}</a>
          </div>

          <!-- sort by -->
          <div class="d-flex align-items-center justify-content-end me-1 sort-by-dropdown">
            <span class="d-none d-sm-block mx-2 text-secondary">{{ 'HOME.SORT_LABEL' | translate }}</span>
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary rounded-pill d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="me-2">{{ 'HOME.SORT_'+ selectedSorting | translate }}</span>
                <svg class="casino-icon me-0" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 120 120">
                  <g>
                    <path style="fill:#FFFFFF;" d="M108.3,55H47c-2-4.7-6.6-8-12-8s-10,3.3-12,8H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7H23c2,4.7,6.6,8,12,8s10-3.3,12-8h61.3c2,0,3.7-1.6,3.7-3.7v-2.7C112,56.6,110.4,55,108.3,55z"/>
                    <path style="fill:#FFFFFF;" d="M108.3,23H85c-2-4.7-6.6-8-12-8s-10,3.3-12,8H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7H61c2,4.7,6.6,8,12,8s10-3.3,12-8h23.3c2,0,3.7-1.6,3.7-3.7v-2.7C112,24.6,110.4,23,108.3,23z"/>
                    <path style="fill:#FFFFFF;" d="M108.3,87H84.5c-2.2-4.2-6.5-7-11.5-7s-9.4,2.8-11.5,7H11.7c-2,0-3.7,1.6-3.7,3.7v2.7c0,2,1.6,3.7,3.7,3.7h49c1.7,5.2,6.6,9,12.4,9s10.7-3.8,12.4-9h23c2,0,3.7-1.6,3.7-3.7v-2.7C112,88.6,110.4,87,108.3,87z"/>
                  </g>
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('AZ')">{{ 'HOME.SORT_AZ' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('ZA')">{{ 'HOME.SORT_ZA' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('POPULAR')">{{ 'HOME.SORT_POPULAR' | translate }}</button></li>
                <li><button class="dropdown-item" type="button" (click)="sortOptionSelected('FEATURED')">{{ 'HOME.SORT_FEATURED' | translate }}</button></li>
              </ul>
            </div>
          </div>

       </div>

        <!-- games-->
        <div *ngIf="games && games.length else noGamesBlock " class="tab-content" id="pills-tabContent">
          <ng-container *ngFor="let category of activeCategories; let i = index">
            <div class="tab-pane fade show" [class.active]="i == 0"
                 [id]="'pills-'+category.toLowerCase().split(' ').join('-')" role="tabpanel"
                 [attr.aria-labelledby]="'pills-'+category.toLowerCase().split(' ').join('-')+'-tab'" tabindex="0">
              <div *ngIf="filteredGamesByCategory[i] && filteredGamesByCategory[i].length; else noMatchBlock ">
                <div class="container-sm px-0 text-center" *ngFor="let chunk of (displayedGamesByCategory[i] | chunkify)">
                  <div class="row g-2 mb-2">
                    <div class="col-12 col-md-6">
                      <div class="row g-2 mb-2">
                        <div class="col-8" *ngIf="chunk[0]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[0].enabled === false}" [src]="chunk[0].image" alt="{{chunk[0].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[0].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[0].category">{{chunk[0].category}}</div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="row g-2">
                            <div class="col-12" *ngIf="chunk[1]">
                              <div class="img-container clickable">
                                <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[1].enabled === false}" [src]="chunk[1].image" alt="{{chunk[1].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[1].id)" loading="lazy">
                                <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[1].category">{{chunk[1].category}}</div>
                              </div>
                            </div>
                            <div class="col-12" *ngIf="chunk[2]">
                              <div class="img-container clickable">
                                <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[2].enabled === false}" [src]="chunk[2].image" alt="{{chunk[2].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[2].id)" loading="lazy">
                                <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[2].category">{{chunk[2].category}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-2">
                        <div class="col-4" *ngIf="chunk[3]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[3].enabled === false}" [src]="chunk[3].image" alt="{{chunk[3].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[3].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[3].category">{{chunk[3].category}}</div>
                          </div>
                        </div>
                        <div class="col-4" *ngIf="chunk[4]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[4].enabled === false}" [src]="chunk[4].image" alt="{{chunk[4].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[4].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[4].category">{{chunk[4].category}}</div>
                          </div>
                        </div>
                        <div class="col-4" *ngIf="chunk[5]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[5].enabled === false}" [src]="chunk[5].image" alt="{{chunk[5].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[5].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[5].category">{{chunk[5].category}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="row g-2 mb-2">
                        <div class="col-4" *ngIf="chunk[6]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[6].enabled === false}" [src]="chunk[6].image" alt="{{chunk[6].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[6].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[6].category">{{chunk[6].category}}</div>
                          </div>
                        </div>
                        <div class="col-4" *ngIf="chunk[7]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[7].enabled === false}" [src]="chunk[7].image" alt="{{chunk[7].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[7].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[7].category">{{chunk[7].category}}</div>
                          </div>
                        </div>
                        <div class="col-4" *ngIf="chunk[8]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[8].enabled === false}" [src]="chunk[8].image" alt="{{chunk[8].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[8].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[8].category">{{chunk[8].category}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-2">
                        <div class="col-4">
                          <div class="row g-2">
                            <div class="col-12" *ngIf="chunk[9]">
                              <div class="img-container clickable">
                                <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[9].enabled === false}" [src]="chunk[9].image" alt="{{chunk[9].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[9].id)" loading="lazy">
                                <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[9].category">{{chunk[9].category}}</div>
                              </div>
                            </div>
                            <div class="col-12" *ngIf="chunk[10]">
                              <div class="img-container clickable">
                                <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[10].enabled === false}" [src]="chunk[10].image" alt="{{chunk[10].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[10].id)" loading="lazy">
                                <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[10].category">{{chunk[10].category}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-8" *ngIf="chunk[11]">
                          <div class="img-container clickable">
                            <img class="img-fluid rounded" [ngClass]="{'disabled-game': chunk[11].enabled === false}" [src]="chunk[11].image" alt="{{chunk[11].name}}" (error)="updateNotFoundImgUrl($event)" (click)="launchGame(chunk[11].id)" loading="lazy">
                            <div class="badge bg-primary-subtle rounded-pill game-label" *ngIf="chunk[11].category">{{chunk[11].category}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--              Load more or less games-->
                <app-item-showcase [totalItems]="filteredGamesByCategory[i].length" [itemsPerPage]="itemsPerPage" [resetFlag]="resetBySortOrFilterNeeded" (itemsToLoad)="loadItems($event)" ></app-item-showcase>
              </div>


            </div>
          </ng-container>
        </div>


      </section>
    </div>

    <div class="mt-4">
      <app-carousel [dataList]="providersArray" [identifier]="'providerList'"></app-carousel>
    </div>

  </div>







</main>

<ng-template #noGamesBlock>
  <div class="container-lg text-center pt-5">
    <div *ngIf="isLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <ng-container *ngIf="!isLoading && games && !games.length">
      <h3>{{'HOME.NOGAMES_MESSAGE' | translate}}</h3>
      <h4>{{'HOME.NOGAMES_TIP' | translate}}</h4>
    </ng-container>
  </div>
</ng-template>

<ng-template #noMatchBlock>
  <div class="container-lg text-center pt-5">
    <div *ngIf="isLoading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <h3 *ngIf="!isLoading">{{'HOME.NOMATCH_MESSAGE' | translate}}</h3>
    <h4 *ngIf="searchTerm.value">{{'HOME.NOMATCH_TIP' | translate}}</h4>
  </div>
</ng-template>


