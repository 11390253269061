import { Component } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TitleService} from "../../services/title.service";
import {ChatService} from "../../services/chat.service";
import {TawkService} from "../../services/tawk.service";
import {TrackingHelperService} from "../../services/tracking-helper.service";
import {Router} from "@angular/router";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-reset-forgot-password-in-lobby',
  templateUrl: './reset-forgot-password-in-lobby.component.html',
  styleUrls: ['./reset-forgot-password-in-lobby.component.scss']
})
export class ResetForgotPasswordInLobbyComponent {

  url: SafeResourceUrl = ""
  lang: string;

  constructor(
    private sanitizer:DomSanitizer,
    private configs: ConfigService,
    private titleService: TitleService,
    private trackingService: TrackingHelperService,
    private router: Router
  ) {
    if (!this.configs.isRivalCasino) {
      //on not rival brands: do not open the lobby iframe but navigate to info/support
      //make it with a guard instead - todo?
      this.router.navigate(['info', 'support'])
    }

    this.lang = localStorage.getItem('lang') ?? 'en';

    this.titleService.setTitle("Reset password");


    let returnUrl = window.location.href.split("/resetPassword")[0];
    let urlWithParams = `https://www.casinocontroller.com/${this.configs.name}/engine/EmbedGame/EmbedGame.php?mode=lobby&lang=${this.lang}&returnUrl=${returnUrl}`;


    //add btag and tracker to url
    let bTag = this.trackingService.getBannerTag();
    if (bTag) {
      urlWithParams += `&btag=${bTag}`
    }
    let t = this.trackingService.getTracker();
    if (t) {
      urlWithParams += `&t=${t}&tracker=${t}`
    }

    // let urlWithParams = `https://www.casinocontroller.com/${this.rivalName}/engine/EmbedGame/EmbedGame.php?mode=lobby`;
    // link for registration
    // let urlWithParams = `https://www.casinocontroller.com/${this.rivalName}/engine/EmbedGame/EmbedGame.php?register=1&lang=${this.lang}&returnUrl=${returnUrl}&chatButton=0s`;
    //found out forgot password link
    // urlWithParams = `https://www.casinocontroller.com/${this.rivalName}/engine/EmbedGame/EmbedGame.php?&mode=lobby&lang=${this.lang}&&returnUrl=${returnUrl}#/reset`;
    urlWithParams += '#/reset'


    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);
  }

}
