import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {

  contactEmail: string;

  constructor(
    private configs: ConfigService,
    private route: ActivatedRoute
  ) {
    this.contactEmail = this.configs.contactEmail;

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const email = params['email'];
      console.log('Email:', email);
      // TODO - unsubscribe from mail list ?
    });
  }


}
