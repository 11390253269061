import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {XmasWinnersService} from "../../services/xmas-winners.service";
import {XmasWinnerModel} from "../../models/xmasWinner.model";
import {ConfigService} from "../../services/config.service";

declare var bootstrap: any;


@Component({
  selector: 'app-xmas',
  templateUrl: './xmas.component.html',
  styleUrls: ['./xmas.component.scss']
})
export class XmasComponent {
  brandName: string;
  assetsSource: string;
  logoFileName: string;
  logoAltText: string;


  audio?: HTMLAudioElement;
  isMuted: boolean = false;

  currentSlide: number = 0;

  calendar: (number | null)[][] = [];

  winners: XmasWinnerModel | undefined;
  today;
  thisMonth;

  playerIndices: { [day: number]: number } = {}; // Track the current player index for each day
  @ViewChildren('rotateContainer') rotateContainers!: QueryList<ElementRef>;
  intervalIds: number[] = []; // Array to store the interval IDs

  backgroundPositions: string[] = [
    'center 25%', // Page 1
    '75% 10%',    // Page 2
    '100% 10%',   // Page 3
    '75% 10%',    // Page 4
    '75% 50%',    // Page 5
    'center',      // Page 6
  ];

  constructor(
    private configs: ConfigService,
    private winnersService: XmasWinnersService
  ) {
    this.brandName = this.configs.brandName;
    this.assetsSource = this.configs.assetsPath;
    this.logoFileName = this.configs.logoName;
    this.logoAltText = this.configs.logoAltText;

    const actualDate = new Date() //todo comment this back when publishing
    // const actualDate = new Date(2024,11,28, 11, 10);
    this.today = actualDate.getUTCDate();
    this.thisMonth = actualDate.getUTCMonth();
  }

  ngOnInit() {
    this.generateCalendar();
    this.loadWinners();

    this.initializeAudio();
  }

  ngAfterViewInit(): void {
    const myCarousel = document.getElementById('XmasCarousel')
    if(myCarousel){
      interface CarouselEvent extends Event {
        to: number;
        from: number;
        direction: string;
      }

      myCarousel.addEventListener('slide.bs.carousel', event => {
        const customEvent = event as CarouselEvent;
        if (customEvent.to !== undefined ) {
          this.currentSlide = customEvent.to
          this.stopRotateWinners();
          //start rotating winners when this slide gets active
          if(this.currentSlide === 5){
            this.rotateWinners()
          }
        }
      })
    }
  }



  goToCalendar(){
    const carousel = new bootstrap.Carousel('#XmasCarousel')
    carousel.to(5)
  }

  initializeAudio() {
    this.audio = document.getElementById('bgMusic') as HTMLAudioElement;
    if (this.audio){
      // Get the stored sound preference from localStorage or set default
      const storedSoundLevel = localStorage.getItem('soundLevel') || 'sound-on';
      this.isMuted = (storedSoundLevel === 'sound-off');
      this.audio.muted = this.isMuted;
    }
  }

  toggleAudio(){
    if (this.audio){
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;

      localStorage.setItem('soundLevel', this.isMuted ? 'sound-off' : 'sound-on');

      // Play or pause audio based on mute status
      if (!this.isMuted) {
        this.audio.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
    }
  }


  generateCalendar() {
    const daysInDecember = 25;
    let firstDay = new Date(new Date().getFullYear(), 11, 1).getDay();  // Dec 1st of current year (0=sunday, 1=monday)
    firstDay = (firstDay === 0) ? 6 : firstDay - 1;

    const totalCells = firstDay + daysInDecember;
    const numOfWeeks = Math.ceil(totalCells / 7);

    let dayCounter = 1;

    // Loop to generate weeks (or rows)
    for (let i = 0; i < numOfWeeks; i++) {
      const week: (number | null)[] = [];
      for (let j = 0; j < 7; j++) {
        const currentCell = i * 7 + j;
        // Fill in the first week with empty spaces if necessary
        if (currentCell < firstDay || dayCounter > 25) {
          week.push(null);  // Empty cell for days before the 1st of the month or after the last day
        } else {
          week.push(dayCounter);  // Add the day number
          dayCounter++;
        }
      }
      this.calendar.push(week);
    }

  }



  loadWinners() {
    this.winnersService.getWinners().subscribe({
      next: data => {
        this.winners = data;
      },
      error: err => console.error('Error loading winners', err)
    })
  }


  rotateWinners() {
    if(this.winners && this.winners.days){
      this.winners.days.forEach((day: any, index: number) => {
        if (day.players && day.players.length > 0) {
          this.playerIndices[index] = 0; // Initialize the index for each day
         // Start rotating players for this day
          const intervalId = setInterval(() => {
            // Ensure the player index wraps around the available players
            this.playerIndices[index]++;
            if (this.playerIndices[index] >= this.winners!.days[index].players.length) {
              this.playerIndices[index] = 0; // Reset to the first player after cycling through all
            }
          }, 5000);

          this.intervalIds.push(intervalId as unknown as number);
        }
      });
    }

  }

  stopRotateWinners() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId)); // Clear each stored interval
    this.intervalIds = []; // Optionally clear the array after stopping
  }

  getCurrentPlayer(dayIndex: number): any {
    // Return the current player for the given day
    return this.winners!.days[dayIndex].players[this.playerIndices[dayIndex]];
  }

  getBackGroundImage(pageNumber: number): string {
    return `url('../../../assets/landingPages/xmas/bgd${pageNumber}.png')`
  }

  getFlareImage(pageNumber: number, flareType: 'after' | 'before', flareNumber: number): string {
    return `url('../../../assets/landingPages/xmas/slide${pageNumber}_flare_${flareNumber}.png')`;
  }

  getBackGroundPosition(pageNumber: number): string {
    return this.backgroundPositions[pageNumber-1];
  }

}
