import {
  Component,
  HostListener,
} from '@angular/core';
import {TitleService} from "./services/title.service";
import {ChatService} from "./services/chat.service";
import {Observable} from "rxjs";
import {TrackingHelperService} from "./services/tracking-helper.service";
import {LanguageService} from "./services/language.service";
import { TawkMessengerAngular } from '@tawk.to/tawk-messenger-angular/dist/tawk-messenger-angular';
import {TawkService} from "./services/tawk.service";
import {Meta} from "@angular/platform-browser";
import {ConfigService} from "./services/config.service";
import {environment} from "../environments/environment";





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  // title = 'rivalFrontend';

  brandName:string;
  chatSystem:string;

  isChatToggled$: Observable<boolean>;
  //we are on play/gameId iframe so we dont need the chatbutton
  isChatBtnShown$: Observable<boolean>;



  constructor(
    private configs: ConfigService,
    private titleService: TitleService,
    private metaService: Meta,
    private chatService: ChatService,
    private trackingService: TrackingHelperService,
    private langService: LanguageService,
    // public TawkMessengerAngular: TawkMessengerAngular
    private tawkService: TawkService
  ) {
    this.brandName = this.configs.brandName;
    this.chatSystem = this.configs.chatSystem;

    this.titleService.setTitle( this.brandName + " Online Casino");

    this.metaService.addTags([
      {name: 'description', content: this.configs.metaDesc},
      {name: 'author', content: this.brandName},
      {name: 'keywords', content: 'online casino, slots, blackjack, live dealer game, table game, gambling bonuses, poker, free spins, real money, cash rewards, big jackpot, deposit bonus, secure transaction, fast payout, bitcoin payment'}
    ])
    this.langService.initializeLanguage();

    this.isChatToggled$ = this.chatService.isChatToggled();
    this.isChatBtnShown$ = this.chatService.isChatBtnShown();

    //tracking and analytics
    this.trackingService.setCookiesFromQueryParams();


  }

  ngOnInit(){
    // //todo implement tawk messenger - this throws dependency error
    // if (this.chatSystem === 'tawk'){
    //   // this.tawkService.SetChatVisibility(true); //todo hide it from the beginning
    //   //   this.TawkMessengerAngular.init({
    // //     propertyId: '6696c9e7becc2fed69266443',
    // //     widgetId: '1i2ug9cme'
    // //   });
    // }


  }


  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event.origin.includes("casinocontroller")){
      // console.log('Received message:', event.data); //TODO comment this out for publishing

      this.trackingService.trackEventFromMessage(event)

      // when game/registration is loaded, push previous page into window.history to be able to navigate back by clicking once on browser's BACK button (but this will load the returnUrl pages if the user refreshes the page)
      if (event.data.action && event.data.action === "GAMES_START_GAME"){
        // this.location.replaceState(`/details/${event.data.id}`);
        // this.location.go(`/details/${event.data.id}`);

        //no-angular version
        // let returnUrl = window.location.href.split("/play")[0];
        // window.history.pushState("", "Game", `${returnUrl}/details/${event.data.id}`)
        // window.history.pushState("", "Game", `${returnUrl}/details/${event.data.id}`)

        //todo find out what it is as I dont remember anymore --> hijack launching game:
        if (!window.location.href.includes("legacy")) {
          // this.router.navigate(['play', `${event.data.id}`])
        }



      }

    }
  }

  openChat() {
    this.chatService.toggleChat(true);
  }


  // public throwTestError(): void {
  //   throw new Error("Sentry Test Error");
  // }

}
