import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {

  leaderboardURL: string = "https://docs.google.com/spreadsheets/d/e/2PACX-1vT2uATYXc0Nsg8GJt7q9c0uiLaCr-eWt2b5d5LE9pJs0jQPI_WXwUFgozEKTsppWqKh0-UYZm9RcN9x/pub?gid=1123951560&single=true&output=csv"


  constructor(
    private http: HttpClient
  ) { }

  getLeaderBoard(code:string){
    return this.http.get(this.leaderboardURL + "&code=" + code, {responseType: 'text'}).pipe(
      map(result => {

        //get data
        const csv2array = (line: string) => line ? line.split(/,(?=(?:[^\"]*\"[^\"]*\")*(?![^\"]*\"))/) : [];
        const splitter = result.split("\r\n");

        let titleRow = csv2array(splitter[0]);
        let updatedRow = csv2array(splitter[1]);
        let codeRow = csv2array(splitter[2]);
        let headersRow = csv2array(splitter[3]);
        let data = splitter.slice(4).map(csv2array)

        const results = {
            title: titleRow[1],
            updated: updatedRow[1],
            headers: headersRow,
            data: data.map(row => row.map(cell => cell.replace(/^"|"$/g, '')))
        };

        // check password
        if (codeRow[1] && codeRow[1].toUpperCase() === code.toUpperCase()){
          return results;
        } else {
          throw new Error('Wrong code')
        }

      }),
      catchError(err => {
        throw err;
      })
    )
  }
}
