import {CanActivateFn, CanMatchFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {GameService} from "../services/game.service";


export const categoryGuard: CanMatchFn = (route, state) => {

  if(state[1].path){
    const requestedCategory = state[1].path

    const gameService = inject(GameService);
    const allowedCategories = gameService.categoriesWithGames;

    if (requestedCategory && allowedCategories.includes(requestedCategory)){
      return true
    }
  }
  return false

};



