import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LeaderboardService} from "../../services/leaderboard.service";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit{
  accessForm!: FormGroup;
  result?: { title: string, updated: string, headers: string[], data: string[][] };
  isLoading = false;
  submit = false;
  lbSubs?: Subscription;
  titleTranslateSubs?: Subscription;
  constructor(
    private lbService: LeaderboardService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private titleService: TitleService
  ) {
    this.titleTranslateSubs?.unsubscribe();
    this.titleTranslateSubs = this.translate.stream("LEADERBOARD.TITLE").subscribe(data => {
      this.titleService.setTitle(data);
    })

  }
  ngOnInit() {
    this.accessForm = new FormGroup({
      code: new FormControl('',[Validators.required, Validators.minLength(2)])
    })
  }

  submitCode(){
    this.submit = true;
    if(this.accessForm.valid){
      // fetch data from leaderboardService
      const code = this.accessForm.value.code;
      this.isLoading = true;
      if (code) {
        this.lbSubs = this.lbService.getLeaderBoard(code).subscribe({
          next: data => {
            this.accessForm.reset();
            this.result = data;
            this.isLoading = false;
          },
          error: (err) => {
            this.toastr.error(`${err}`, this.translate.instant('LEADERBOARD.INVALID_MESSAGE'), {toastClass: 'ngx-toastr toast-danger yourclass'})
            this.accessForm.reset();
            this.isLoading = false;
          }
        })
      }
    }
  }

  ngOnDestroy(){
    this.lbSubs?.unsubscribe();
    this.titleTranslateSubs?.unsubscribe();
  }

}
