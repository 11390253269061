import {Component, OnDestroy, Sanitizer} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom, Subscription} from "rxjs";
import {GameService} from "../../services/game.service";
import {Game} from "../../models/game.class";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../services/user.service";
import {JackpotModel} from "../../models/jackpot.model";
import {UserModel} from "../../models/user.model";
import {TranslateService} from "@ngx-translate/core";
import {ProviderModel} from "../../models/provider.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TitleService} from "../../services/title.service";

declare var bootstrap: any;


@Component({
  selector: 'app-selected-game',
  templateUrl: './selected-game.component.html',
  styleUrls: ['./selected-game.component.scss']
})
export class SelectedGameComponent implements OnDestroy {

  game?: Game;
  gameSubs? : Subscription;
  jackpotSubs?: Subscription;
  jackpot?: JackpotModel;
  user?: UserModel | null;
  userSubs?: Subscription;
  categoryToNavigate: string = '';
  categoryTranslated: string = '';

  gameUrl!: SafeResourceUrl | null;
  isReloading: boolean = false;    //games reloading after log in
  isLoggingIn: boolean = false;  //log in is loading


  badges: Array<{text: string, routerLink: string}> = [];

  gamesSelectionAll: Game[] = [];
  gamesSelectionByCategory: Game[] = [];
  gamesSelectionByProvider: Game[] = [];
  gamesSelectionLive: Game[] = [];
  gamesSelectionNew: Game[] = [];
  gamesSelectionTop: Game[] = [];
  gamesSelectionFeatured: Game[] = [];

  providers!: ProviderModel[];
  providerSubs!: Subscription;


  constructor(
    private activatedRoute: ActivatedRoute,
    private gameService: GameService,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
     private sanitizer: DomSanitizer,
    private titleService: TitleService
  ) {
    this.providerSubs = this.gameService.providers$.subscribe(p => {
      this.providers = p;
      // this.providers = p.filter(p=>p.gameList && p.gameList.length);
      // if (!this.providers.length){
      //   this.providers = this.gameService.providers;
      //   console.log(this.providers)
      // }
    })

    this.userService.userObject.subscribe(u=>{
      this.user = u;
      this.isLoggingIn = false;
    })

  }

  ngOnInit(){
    this.activatedRoute.paramMap.subscribe(params => {
      let gameId = params.get('id')

      if (gameId !== null){
        let id = +gameId;
        this.gameSubs?.unsubscribe();
        this.gameSubs =  this.gameService.getGames().subscribe({
          next: async games => {
            if (games && games.length) {
              this.game = games.get(id);
              // console.log(this.game);
              if (this.game){
                this.titleService.setTitle(this.game.name);

                this.categoryToNavigate = this.gameService.getCategoryToNavigate(this.game.category);
                // this.categoryTranslated = this.translate.instant(`CATEGORIES.${this.categoryToNavigate.split(' ').join('').toUpperCase()}`);
                // translate.instant is not working if refreshed? - use translate.stream instead (see selected provider)
                //see https://stackoverflow.com/questions/46216185/ngx-translate-instant-returns-key-instead-of-value
                this.categoryTranslated = await firstValueFrom(this.translate.get(`CATEGORIES.${this.categoryToNavigate.split(' ').join('').toUpperCase()}`))


                this.getBadges();

                if (this.game.launchUrl){
                  this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.game.launchUrl);
                  this.isReloading = false;
                }

                //set carousels - get data to send to carousel child components
                this.gamesSelectionAll = this.pickRandomGames(games.all, 16);

                if(this.game.name.toLowerCase().includes('live')){
                  this.gamesSelectionLive = this.pickRandomGames(games.all.filter(game => game.name.toLowerCase().includes('live')), 16);
                }
                if (this.game.tag) {
                  this.gamesSelectionNew = this.pickRandomGames(games.all.filter(game => game.tag === "NEW"), 16);
                  this.gamesSelectionTop = this.pickRandomGames(games.all.filter(game => game.tag === "HOT"), 16);
                  this.gamesSelectionFeatured = this.pickRandomGames(games.all.filter(game => game.tag === "FEATURED"), 16);
                }
                this.gamesSelectionByCategory = this.pickRandomGames(this.gameService.getGamesOfValidCategory(this.categoryToNavigate, games),16);
                this.gamesSelectionByProvider = this.pickRandomGames(games.all.filter(g => g.publisher === this.game?.publisher),16);

              } else { //unexisting game-id
                this.router.navigate(['not-found']);
              }
            } else if (games) { //no games available: []
              this.router.navigate([''])
            }
            //else: !games (-> !this.game), spinner goes until we communicate with server


            //get jackpots related to this game
            if (this.game && this.user){
              this.jackpotSubs?.unsubscribe();
              this.jackpotSubs = this.gameService.getJackpotsForAGame(this.game.id).subscribe( data => {
                this.jackpot = data.result.jackpots.find(j => j.currency === this.user!.currency)
                // console.log(this.jackpot)
                if(this.jackpot){
                  const jackpotSummary = this.translate.instant('SELECTED_GAME.JACKPOT_TOASTR_MESSAGE', {
                    amount: this.jackpot.value,
                    currency: this.jackpot.currency
                  });
                  this.toastr.info(jackpotSummary, this.translate.instant('SELECTED_GAME.JACKPOT_TOASTR_H1'), {
                    enableHtml: true, toastClass: 'ngx-toastr yourclass'
                  });
                }
              })
            }

          },
          error: (err) => {
            this.toastr.error(this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), this.translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1'), {toastClass: 'ngx-toastr yourclass'});
          }
        })
      }
    })
  }



  getBadges() {
    if (this.game){
      this.badges = [
        { text: this.game.category, routerLink: `/games/${this.categoryToNavigate}` },
        // { text: this.game.publisher, routerLink: `/providers/${this.providers.find(p => p.name.split(" ")[0] === this.game?.publisher.)?.routerLink}` }
      ];
      let provider = this.providers.find(p => this.game?.publisher.toLowerCase().includes(p.name.split(" ")[0].toLowerCase()))
      if (!provider){
        this.badges.push({ text: this.game.publisher, routerLink: `/providers/${this.game.publisher.toLowerCase()}` });
      } else {
        this.badges.push({ text: this.game.publisher, routerLink: `/providers/${provider.routerLink}` });
      }


      if (this.game.category && this.game.category.toLowerCase() !== this.categoryTranslated.toLowerCase()) {
        this.badges.splice(1, 0, { text: `${this.categoryTranslated}`, routerLink: `/games/${this.categoryToNavigate}` });
      }

      if (this.game.name && this.game.name.toLowerCase().includes('live')) {
        this.badges.splice(1, 0, { text: `${this.translate.instant('CATEGORIES.LIVE')}`, routerLink: '/games/live' });
      }

      if (this.game.category && this.game.category.toLowerCase().includes("instanet")) {
        this.badges.push({ text: `${this.translate.instant('CATEGORIES.EXCLUSIVE')}`, routerLink: '/games/exclusive' });
      }

      if (this.game.tag && this.game.tag === "NEW") {
        this.badges.push({ text: `${this.translate.instant('CATEGORIES.NEW')}`, routerLink: '/games/new' });
      }
      if (this.game.tag && this.game.tag === "HOT") {
        this.badges.push({ text: `${this.translate.instant('CATEGORIES.TOP')}`, routerLink: '/games/top' });
      }
      if (this.game.tag && this.game.tag === "FEATURED") {
        this.badges.push({ text: `${this.translate.instant('CATEGORIES.FEATURED')}`, routerLink: '/games/featured' });
      }
    }
  }


  launchGame() {
    if (this.user && this.game && this.game.launchUrl){
      this.router.navigate(['play', this.game.id] )
    } else {
      // needs login
      // this.isReloading = true; //TODO fix this - carousels are disappearing on modal opening
      this.isLoggingIn = true;

      //deactivate Bootstrap modals preventing text input fields focus
      bootstrap.Modal.prototype._initializeFocusTrap = function () { return { activate: function () { }, deactivate: function () { } } };
      const signInModal = new bootstrap.Modal(document.getElementById('signInModal'), {})
      signInModal.show()
    }
  }

  pickRandomGames(games: Game[], count: number): Game[] {
    let enabledGames = games.filter(game => game.enabled !== false);
    let filteredGames = enabledGames.filter(game => game.id !== this.game?.id);

    let selectedGames: Game[] = [];

    if(filteredGames.length){
      while (selectedGames.length < Math.min(count, filteredGames.length)) {
        let randomIndex = Math.floor(Math.random() * filteredGames.length);
        let selectedGame = filteredGames[randomIndex];

        if (!selectedGames.find(game => game.id === selectedGame.id)) {
          selectedGames.push(selectedGame);
        }
      }
    }

    return selectedGames;
  }


  updateNotFoundImgUrl(event: Event) {
    //   (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=game+image`;
    (event.target as HTMLImageElement).src = `https://placehold.co/512x512/3b3b3b/F0F0F0/png?text=${(event.target as HTMLImageElement).alt}`;
  }

  ngOnDestroy(): void {
    this.gameSubs?.unsubscribe();
    this.jackpotSubs?.unsubscribe();
    this.providerSubs?.unsubscribe();
    this.userSubs?.unsubscribe();
  }

}
