import {Component, ElementRef, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TitleService} from "../../services/title.service";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  //todo: delete all this because it will work from API
  regUrl: SafeResourceUrl = ""
  lang:string;

  titleTranslateSubs?: Subscription;


  constructor(
    private configs: ConfigService,
    private sanitizer:DomSanitizer,
    private titleService: TitleService,
    private translate: TranslateService
  ) {
    this.lang = localStorage.getItem('lang') ?? 'en';

    this.titleTranslateSubs?.unsubscribe();
    this.titleTranslateSubs = this.translate.stream("REGISTER.TITLE").subscribe(data => {
      this.titleService.setTitle(data);
    })

    // until we get our API endpoint
    // this.regUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://demo.casinocontroller.com/${this.rivalName}/engine/EmbedGame/EmbedGame.php?register=1&lang=${this.lang}`)

    let returnUrl = window.location.href.split("/registration")[0];
    let urlWithParams = `https://www.casinocontroller.com/${this.configs.name}/engine/EmbedGame/EmbedGame.php?register=1&lang=${this.lang}&returnUrl=${returnUrl}&chatButton=0s`;
    this.regUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);
  }

  //todo: hack registration process - save user password not to have log in on our site



}
