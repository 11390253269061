<main class="container-fluid px-0 pb-3 pb-lg-5">

  <div class="px-3 px-md-5 py-2 py-md-0 bg-darkest">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="h3 fw-bold mb-0">{{ 'LEADERBOARD.TITLE' | translate}}</h1>
        <div class="category-icon-overlay">
          <img src="assets/icons/leaderboard_big.svg" alt="leaderboard background image" width="100%">
        </div>
      </div>
    </div>
  </div>

  <div class="container-lg" id="leaderboard">
    <section *ngIf="!result" class="mt-5 p-3 col-10 col-sm-8 col-xl-6 offset-1 offset-sm-2 offset-xl-3">
      <h1 class="text-center mb-5">{{ 'LEADERBOARD.H1' | translate | uppercase }}</h1>

      <div class="card bg-darker text-center">
        <div class="card-header py-3">
          <label *ngIf="!submit" for="code" class="h5 mb-0">{{ 'LEADERBOARD.FORM_LABEL' | translate | uppercase }}</label>
          <label *ngIf="isLoading" for="code" class="h5 mb-0">{{ 'LEADERBOARD.LOADING_LABEL' | translate | uppercase }}</label>
          <label *ngIf="submit && !isLoading" for="code" class="h5 mb-0 text-danger">{{ 'LEADERBOARD.INVALID_LABEL' | translate | uppercase }}</label>
        </div>
        <form [formGroup]="accessForm">
          <div class="card-body py-5">
            <div class="row mb-4">
              <div class="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2">
                <input type="password" class="form-control form-control-lg" id="code" formControlName="code" [ngClass]="{'is-invalid': submit && accessForm.controls['code'].errors}">
              </div>
            </div>
            <button *ngIf="!isLoading" type="button" class="btn btn-outline-primary mt-3 px-5" [ngClass]="{'btn-outline-danger': submit && accessForm.invalid}" (click)="submitCode()" [disabled]="accessForm.invalid">{{ 'LEADERBOARD.BTN_LABEL' | translate }}</button>
            <div *ngIf="isLoading" class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section *ngIf="result" class="mt-5 p-3 col-10 offset-1 col-md-6 offset-md-3">
      <div class="card bg-darker text-center">
        <div class="card-header py-3">
          <h3 class="">{{result.title | uppercase}}</h3>
          <p class="text-center mb-0">{{ 'LEADERBOARD.UPDATED_LABEL' | translate }}: {{result.updated}}</p>
        </div>
        <div class="card-body table-responsive-sm p-0">
          <table *ngIf="result.data" class="table text-center mb-0 table-borderless table-striped">
            <thead>
            <tr>
              <th *ngFor="let header of result.headers, let i= index;" scope="col" class="border-bottom" [ngClass]="{'border-end': i === 0}">{{header | uppercase}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of result.data">
              <td [ngClass]="{'border-end': i === 0}" *ngFor="let cell of row; let i = index;">{{cell}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>



</main>



